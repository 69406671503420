<template>
  <div>
    <div :style="{width: '100%'}">

      <v-tabs background-color="transparent"
              show-arrows
              centered>

        <v-tab exact
               to="/supervisor-view/gifty">
          <v-icon left>mdi-text-box-search</v-icon>
          Recherche
        </v-tab>

        <v-tab exact
               to="/supervisor-view/gifty/rewards">
          <v-icon left>mdi-gift</v-icon>
          Récompenses
        </v-tab>

        <v-tab exact
               to="/supervisor-view/gifty/liaisons">
          <v-icon left>mdi-map-marker-distance</v-icon>
          Pos liés à Gifty
        </v-tab>

      </v-tabs>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>